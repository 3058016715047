export const PRODUCTS = {
  1: {
    id: 1,
    title: "Crédito Pessoal iti",
    image: "credito-pessoal-iti",
    description: "Crédito Pessoal iti",
    can: {
      business: "loans",
      action: "sell",
    },
  },
  6: {
    id: 6,
    title: "Microcrédito Itaú",
    image: "microcredito",
    description: "Microcrédito Itaú",
    can: {
      business: "microloans",
      action: "sell",
    },
  },
  5: {
    id: 5,
    title: "Crédito Pessoal Itaú",
    image: "crediario",
    description: "Crédito Pessoal Itaú",
    can: {
      business: "itiLoans",
      action: "sell",
    },
  },
  2: {
    id: 2,
    title: "Microcrédito iti",
    image: "microcreditoiti",
    description: "Microcrédito iti",
    can: {
      business: "itiMicroloans",
      action: "sell",
    },
  },
  3: {
    id: 3,
    title: "Conta iti",
    image: "iti",
    description: "Conta iti",
    can: {
      business: "iti",
      action: "sell",
    },
  },
  8: {
    id: 8,
    title: "Cartão iti",
    image: "cartao-iti",
    description: "Cartão iti",
    can: {
      business: "itiCard",
      action: "sell",
    },
  },
  13: {
    id: 13,
    title: "iCDC",
    image: "icdc",
    description: "iCDC",
    can: {
      business: "icdc",
      action: "sell",
    },
  },
  14: {
    id: 14,
    title: "Adquirência iti",
    image: "adquirencia-iti",
    description: "Adquirência iti",
    can: {
      business: "itiAcquisition",
      action: "sell",
    },
  },
}
