const useCardLink = (businessId, title, openIframe) => {
  const activateModal = (event) => {
    event.preventDefault()

    openIframe(businessId, title)
  }

  return {
    activateModal,
  }
}

export default useCardLink
