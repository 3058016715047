import Icon from "components/Icon"
import Img from "components/Image"

import useCardLink from "./hooks"

import "./CardLink.styl"

const CardLink = ({ id, title, image, description, openModal }) => {
  const { activateModal } = useCardLink(id, title, openModal)

  return (
    <div className="card-link">
      <a
        id={image}
        className="card-link__action"
        href={image}
        onClick={activateModal}
      >
        <Img className="card-link__image" file={image} />
        <p className="card-link__text">{description}</p>
        <Icon name="arrow-right" />
      </a>
    </div>
  )
}

export default CardLink
