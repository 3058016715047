import PageHeader from "components/PageHeader"
import LoansConsult from "components/LoansConsult"
import Products from "./components/Products"
import Schedule from "components/Schedule"

import "./Home.styl"

const Home = () => (
  <div className="home-page">
    <PageHeader title="Início" variant="medium" />
    <section className="home-page__content">
      <LoansConsult />
      <Schedule multiproduct={true} />
      <Products />
    </section>
  </div>
)

export default Home
