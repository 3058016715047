import { useCallback, useEffect, useState } from "react"

import auth from "utils/auth"
import services from "services"
import scroll from "utils/scroll"

const useProducts = () => {
  const user = auth.getUser()

  const [iframe, setIframe] = useState({ title: "", list: null })
  const [productIds, setProductIds] = useState([])

  const sortBusiness = useCallback((listBusiness) => {
    const productIdsSorted = listBusiness.map(
      (business) => business.business_id
    )
    setProductIds(productIdsSorted)
  }, [])

  const fetchBusiness = useCallback(async () => {
    return services.user.business({ user_id: user.user_id })
  }, [user.user_id])

  const fetchIframe = (businessId, title) => {
    services.bases.iframe({ business_id: businessId }).then((iframe_list) => {
      const hasJustOneIframe = iframe_list && iframe_list.length === 1
      const iframeName = hasJustOneIframe ? iframe_list[0].title : title

      setIframe({ title: iframeName, list: iframe_list })
    })
  }

  const openIframe = (businessId, title) => {
    scroll.body.disabled()
    fetchIframe(businessId, title)
  }

  const closeIframe = () => {
    scroll.body.enable()
    setIframe((prev) => ({ ...prev, list: null }))
  }

  useEffect(() => {
    fetchBusiness().then(sortBusiness)
  }, [fetchBusiness, sortBusiness])

  return { openIframe, closeIframe, iframe, productIds }
}

export default useProducts
