import If from "components/If"
import Can from "components/Can"
import Iframe from "components/Iframe"
import CardLink from "components/CardLink"

import useProducts from "./hooks"

import { PRODUCTS } from "./constants"

import "./Products.styl"
import Card from "components/Card"

const Products = () => {
  const { openIframe, closeIframe, iframe, productIds } = useProducts()

  return (
    <Can
      business={[
        "loans",
        "microloans",
        "iti",
        "itiLoans",
        "itiMicroloans",
        "itiCard",
        "icdc",
      ]}
      do={["sell", "sell", "sell", "sell", "sell", "sell", "sell"]}
    >
      <div className="products">
        <Card className="products__card" title="Nova proposta" spacing="none">
          <ul className="products__list">
            {productIds.map((productId, index) => {
              return (
                <Can
                  key={`${PRODUCTS[productId]?.id}${index}`}
                  business={PRODUCTS[productId]?.can?.business}
                  action={PRODUCTS[productId]?.can?.action}
                >
                  <li
                    className="products__item"
                    aria-label={PRODUCTS[productId]?.title}
                  >
                    <CardLink openModal={openIframe} {...PRODUCTS[productId]} />
                  </li>
                </Can>
              )
            })}
          </ul>
        </Card>
        <If condition={iframe.list && iframe.list.length > 0}>
          <Iframe
            iframes={iframe?.list}
            title={iframe?.title}
            closeIframe={closeIframe}
          />
        </If>
      </div>
    </Can>
  )
}

export default Products
